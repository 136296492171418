import * as React from "react";
import Container from "../Container/Container";
import { Link } from "gatsby";
import SocialLinks from "../Social/SocialLinks";
import "./Footer.scss";

// markup
export default function Footer(props) {
  return (
    <footer
      className={
        props.type ? "footer footer--" + props.type : "footer footer--default"
      }
    >
      <Container name="footer">
        <p className="footer__copyright">
          Copyright &copy; {new Date().getFullYear()} Yvette Russell
        </p>
        <nav className="footer__nav">
          <ul className="footer__nav-list">
            <li className="footer__nav-item">
              <Link to="/" className="footer__nav-link">
                Home
              </Link>
            </li>
            <li className="footer__nav-item">
              <Link to="/about" className="footer__nav-link">
                About
              </Link>
            </li>
            <li className="footer__nav-item">
              <Link to="/books" className="footer__nav-link">
                Books
              </Link>
            </li>
            <li className="footer__nav-item">
              <Link to="/contact" className="footer__nav-link">
                Contact
              </Link>
            </li>
            <li className="footer__nav-item">
              <Link to="https://yvetterussell.com/links" 
                target="_blank" 
                rel="noreferrer" 
                className="footer__nav-link">
                Links
              </Link>
            </li>
          </ul>
          <SocialLinks />
        </nav>
      </Container>
    </footer>
  );
}
