import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header/Header";
import Hero from "../components/Heading/Hero";
import HeroHeading from "../components/Heading/HeroHeading";
import Container from "../components/Container/Container";
import SubscribeCallout from "../components/SubscribeCallout/SubscribeCallout";
import Footer from "../components/Footer/Footer";
import { Script } from "gatsby";
import "./Default.scss";

export default function Home() {
  return (
    <section>
      <Helmet defer={false}>
        <Script async src="https://www.googletagmanager.com/gtag/js?id=G-WWB949CBV5" />
        <meta charSet="utf-8" />
        <title>Yvette Russell, author &amp; illustrator</title>
        <meta
          name="description"
          content="Yvette Russell is an author of paranormal new and young 
          adult fiction and author of THE PSYCHIC NEXT DOOR."
        />
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:site" content="@yvetterussell"/>
        <meta name="twitter:title" content="Yvette Russell, author & illustrator"/>
        <meta name="twitter:description" content="The homepage of Yvette Russell, author of horror and paranormal YA & NA fiction. Read my books for free or follow me on social media."/>
        <meta name="twitter:image" content="https://www.yvetterussell.com/assets/twitter/twitter-heading.png"/>
      </Helmet>
      <Header />
      <Hero>
        <HeroHeading>
          Hi! I’m <strong>Yvette Russell</strong>, an{" "}
          <strong>author & illustrator</strong> with a love for all things{" "}
          <strong>dark &amp; mysterious</strong>
        </HeroHeading>
      </Hero>
      <Container name="intro-page" type="background-decor">
        <SubscribeCallout type="overlap" />
      </Container>
      <Footer />
    </section>
  );
}
