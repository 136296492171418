import React from "react";
import Button from "../Button/Button";
import "./HeroHeading.scss";

export default function PageHeading(props) {
  return (
    <div className="hero-heading">
      <p className="hero-heading__intro u-mar-btm-lg">{props.children}</p>
      <div className="hero-heading__buttons">
        <Button url="/books" type="primary">Check out my books</Button>
        <Button url="/about">Get to Know Me</Button>
      </div>
    </div>
  );
}
